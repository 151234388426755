import { axiosInstance } from "./utils/API";
import { useEffect } from "react";
import MainRouter from "./Router";
import { LoadingComponent } from "./Components/Loading";
import { useGetSlidersQuery } from "./Features/Api/ProductApiSlice";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  useEffect(() => {
    // counting visitors
    const countVisitor = async () => {
      try {
        await axiosInstance.put("/visitors");
      } catch (err) {
        console.error(err);
      }
    };
    countVisitor();
  }, []);

  const { isLoading } = useGetSlidersQuery();

  return isLoading ? <LoadingComponent /> : <MainRouter />;
}

export default App;
