import React from "react";
import Popular from "../Components/Popular/Popular";
import NewCollections from "../Components/NewCollections/NewCollections";
import FeedbackCorner from "../Components/FeedbackCorner/FeedbackCorner";

import { Grid } from "@mui/material";

import "./CSS/Home.css";
import Carousel from "../Components/Carousel/Carousel";

function Home() {
  return (
    <div>
      <Grid container>
        <Carousel />
      </Grid>
      <NewCollections />
      <Popular />
      <FeedbackCorner />
    </div>
  );
}
export default Home;
