import React, { useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import Feedback from "./Feedback";
import { useGetFeaturedFeedbacksQuery } from "../../Features/Api/ProductApiSlice";
import "./FeedbackCorner.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { grey } from "@mui/material/colors";

const Dot = styled("div")(({ theme, defaultChecked = false }) => ({
  width: defaultChecked ? 12 : 10,
  height: 10,
  borderRadius: 6,
  transform: !defaultChecked && "scale(.8)",
  transition: theme.transitions.create([
    "transform",
    "background-color",
    "width",
  ]),
  backgroundColor: defaultChecked
    ? theme.palette.primary.main
    : theme.palette.primary.light + 50,
}));

const ArrowButton = styled("div")(({ defaultValue = "left", theme }) => ({
  position: "absolute",
  top: "50%",
  bottom: "50%",
  zIndex: 5,
  [defaultValue]: -24,
  [theme.breakpoints.down("md")]: {
    [defaultValue]: -16,
  },
  padding: theme.spacing(1),
  color: "#fff",
}));

function FeedbackCorner() {
  const [page, setPage] = useState(1);
  const { data, isFetching, error } = useGetFeaturedFeedbacksQuery(page);

  const feedbacks = !data?.data?.length ? [] : data?.data;
  const totalPages = data?.totalPages ?? 0;

  const handleNextPage = useCallback(() => {
    setPage((prev) => (prev >= totalPages ? totalPages : prev + 1));
  }, [totalPages]);

  const handlePrevPage = useCallback(() => {
    setPage((prev) => (prev <= 1 ? 1 : prev - 1));
  }, []);

  if (error) return;

  const renderFeedbacks = feedbacks?.map((item, i) => {
    return (
      <Grid
        container
        key={i}
        item
        xs={12}
        md={6}
        lg={4}
        className="feedback-item"
      >
        <Feedback feedback={item} i={i} />
      </Grid>
    );
  });

  const renderDots = Array.from({ length: totalPages }).map((_, i) => (
    <Dot
      defaultChecked={page === i + 1}
      onClick={() => setPage(i + 1)}
      key={i}
    />
  ));

  const ActionArrowButtons = ({ onClick, disabled, direction = "right" }) => (
    <ArrowButton defaultValue={direction}>
      <IconButton color="primary" disabled={disabled} onClick={onClick}>
        {direction === "left" ? (
          <ArrowBackIosIcon sx={{ color: "#fff" }} />
        ) : (
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        )}
      </IconButton>
    </ArrowButton>
  );

  return (
    <Container className="feedback">
      <Typography className="title-typo">Feedback Corner</Typography>
      <Grid
        container
        sx={{ position: "relative", px: 2 }}
        item
        className="feedback-list"
      >
        {isFetching ? (
          <Box
            width={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={215}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            <ActionArrowButtons
              disabled={page === 1}
              onClick={handlePrevPage}
              direction="left"
            />
            {renderFeedbacks}
            <ActionArrowButtons
              disabled={page === totalPages}
              onClick={handleNextPage}
              direction="right"
            />
          </>
        )}
      </Grid>
      <Box mt={2} gap={1} display="flex" justifyContent="center">
        {renderDots}
      </Box>
    </Container>
  );
}
export default FeedbackCorner;
