import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Grid, styled } from "@mui/material";
import "./Carousel.css";

import { useGetSlidersQuery } from "../../Features/Api/ProductApiSlice";
import { storageUrl } from "../../utils/API";

function Carousel() {
  const { data } = useGetSlidersQuery();
  const images = useMemo(() => (!data?.data?.length ? [] : data?.data), [data]);

  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 3500);
  });

  const slideRight = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  const bubbles = Array.from({ length: 20 }).map((_, i) => (
    <div className="bubble" key={i}>
      <span className="dot" />
    </div>
  ));

  return (
    <Grid
      className="carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <Grid className="carousel_wrapper" position={"relative"}>
        {images.map((image, index) => {
          return (
            /* (condition) ? true : false */

            <Grid
              key={index}
              className={
                index === current
                  ? "carousel_card carousel_card-active"
                  : "carousel_card"
              }
            >
              <img
                className="card_image"
                src={storageUrl.concat(image.path)}
                alt=""
              />
              <Grid className="card_overlay"></Grid>
            </Grid>
          );
        })}
        <div className="carousel_arrow_left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="carousel_arrow_right" onClick={slideRight}>
          &rsaquo;
        </div>
        <Grid className="carousel_pagination">
          {images.map((_, index) => {
            return (
              <Grid
                key={index}
                className={
                  index === current
                    ? "pagination_dot pagination_dot-active"
                    : "pagination_dot"
                }
                onClick={() => setCurrent(index)}
              ></Grid>
            );
          })}
        </Grid>
        {bubbles}
      </Grid>
    </Grid>
  );
}

export default Carousel;
