import { forwardRef, useState } from "react";

import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export const withSnackbar = (Wrapper) => {
  const EnhancedComponent = (props) => {
    const initValue = {
      message: "",
      variant: "success",
      open: false,
    };
    const [snackbarData, setSnackbarData] = useState(initValue);
    const openSnackbar = (message, variant = "error") =>
      setSnackbarData({ message, variant, open: true });

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackbarData(initValue);
    };
    return (
      <>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snackbarData.open}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackbarData.variant}
              sx={{ width: "100%" }}
            >
              {snackbarData.message}
            </Alert>
          </Snackbar>
        </Stack>
        <Wrapper openSnackbar={openSnackbar} {...props} />
      </>
    );
  };
  return EnhancedComponent;
};
