import { Box, LinearProgress } from "@mui/material";
import React from "react";

import logo from "../Assets/logo.png";

function Loading() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      gap={1}
    >
      <Box component={"img"} src={logo} width={100} height={100} />
      <Box width={100}>
        <LinearProgress color="primary" />
      </Box>
    </Box>
  );
}

export default Loading;
