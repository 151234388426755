import React, { useState } from "react";
import { Dialog, Zoom } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { StyledFab } from "./Styles";
import FeedbackForm from "./Form";

function Feedback() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        TransitionComponent={Zoom}
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
      >
        <FeedbackForm handleClose={handleClose} />
      </Dialog>
      <StyledFab onClick={handleOpen}>
        <AddCommentIcon />
      </StyledFab>
    </>
  );
}

export default Feedback;
