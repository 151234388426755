import { Box, DialogContent, Stack, Typography } from "@mui/material";

import { StyledButton, StyledInput } from "../Feedback/Styles";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { withSnackbar } from "../../HOC/withSnackbar";
import { axiosInstance } from "../../utils/API";

const initValue = { email: "" };

const validations = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

const SubscriptionForm = ({ handleClose, openSnackbar, productId }) => {
  const handleSubmit = async (values, form) => {
    try {
      const body = {
        email: values.email,
        product_id: productId,
      };
      await axiosInstance.post("/subscriptions", body);
      form.resetForm();
      openSnackbar(
        "You have successfully subscribed to this Product. You will get notify when the product back to store. Thank you!",
        "success"
      );
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (er) {
      openSnackbar("Product subscriptions failed! Please try again Later.");
    }
  };

  return (
    <DialogContent>
      <Formik
        initialValues={initValue}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <Stack gap={2}>
              <div>
                <Typography variant="h6" fontWeight="bold" color="text.primary">
                  Product out of stock subscribtion
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  This feature will notify you when this product back to stock
                </Typography>
              </div>
              <div>
                <Field
                  as={StyledInput}
                  name="email"
                  error={!!touched?.email && !!errors?.email}
                  placeholder="Enter your email here *"
                />
                <Typography variant="caption" color="error">
                  {!!touched?.email && errors?.email}
                </Typography>
              </div>

              <Box display="flex" justifyContent="space-between">
                <StyledButton onClick={handleClose} color="inherit">
                  Cancel
                </StyledButton>
                <StyledButton disabled={isSubmitting} type="submit">
                  Subscribe now
                </StyledButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
};

export default withSnackbar(SubscriptionForm);
