import axios from "axios";

const findBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return "http://localhost:8800/";

    default:
      return "https://prod.aqua-experts.com/";
  }
};

export const baseURL = findBaseUrl().concat("api/v1");
export const storageUrl = findBaseUrl();

export const API_URL = "https://app.aqua-experts.com";

export const axiosInstance = axios.create({
  baseURL,
});
