import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { fCurrency } from "../../utils/format-number";

import Label from "../label/index";
import "./product-card.css";
import { Dialog, Tooltip, Zoom, styled, tooltipClasses } from "@mui/material";
import { StyledButton, StyledInput } from "../Feedback/Styles";
import SubscriptionForm from "./Form";
import { storageUrl } from "../../utils/API";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

// ----------------------------------------------------------------------

export default function ShopProductCard({ product }) {
  const [status, setStates] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (product.outOfStock) {
      setStates("outOfStock");
    } else if (!product.outOfStock && product.sale) {
      setStates("sale");
    }
  }, [product]);

  const saleLabel = (
    <Label
      variant="filled"
      color="info"
      sx={{
        zIndex: 9,
        top: 16,
        right: 16,
        position: "absolute",
        textTransform: "uppercase",
      }}
    >
      Sale
    </Label>
  );

  const outOfStockLabel = (
    <HtmlTooltip
      title={
        <Stack gap={1} component="div">
          <div>
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
              color="text.primary"
            >
              Product subscriptions
            </Typography>
            <Typography variant="caption" color="text.secondary">
              This feature will notify you when this product back to stock
            </Typography>
          </div>
          {/* <StyledInput placeholder="Your Email" size="small" /> */}
          <StyledButton onClick={handleOpen}>Subscribe</StyledButton>
        </Stack>
      }
    >
      <Label
        variant="filled"
        color="error"
        sx={{
          zIndex: 9,
          top: 16,
          right: 16,
          position: "absolute",
          textTransform: "uppercase",
          gap: 1,
        }}
      >
        <NotificationsActiveIcon sx={{ fontSize: 16 }} />
        Out of Stock
      </Label>
    </HtmlTooltip>
  );

  const renderImg = (
    <Box
      component="img"
      alt={product.name}
      src={storageUrl.concat(!product.images?.length ? "" : product.images[0])}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: "cover",
        position: "absolute",
      }}
    />
  );

  const renderPrice = (
    <Typography className="product-price">
      NOK {product.price}
      <Typography component="span" className="old-product-price">
        {product.oldPrice && `NOK ${product.oldPrice}`}
      </Typography>
    </Typography>
  );

  return (
    <div className="dev-container">
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Zoom}
      >
        <SubscriptionForm handleClose={handleClose} productId={product?.id} />
      </Dialog>
      <Link
        to="/product"
        state={{ data: product }}
        className="product-card-link"
      >
        <Card className="card-container">
          <Box sx={{ pt: "100%", position: "relative" }}>
            {status === "outOfStock" && outOfStockLabel}
            {status === "sale" && saleLabel}
            {renderImg}
          </Box>

          <Grid spacing={2} sx={{ px: 2, pt: 1, pb: 2 }}>
            <Typography
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow="hiddenp"
              color="primary"
              variant="subtitle2"
            >
              <Typography display={"inline"} color={"text.primary"}>
                {product?.name}{" "}
              </Typography>
              {product?.sci_name && `(${product?.sci_name})`}
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {renderPrice}
            </Stack>
          </Grid>
        </Card>
      </Link>
    </div>
  );
}

ShopProductCard.propTypes = {
  product: PropTypes.object,
};
