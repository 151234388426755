import { Button, Fab, InputBase, styled } from "@mui/material";

export const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 35,
  right: 35,
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    bottom: 18,
    right: 18,
  },
}));

export const EmojiBox = styled("div")(({ theme, defaultChecked }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: defaultChecked
    ? theme.palette.primary.light
    : theme.palette.primary.main + "10",
  borderRadius: "50%",
  border: "1px solid",
  borderColor: theme.palette.primary.main + 40,
  fontSize: 28,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
    fontSize: 18,
  },
  transform: defaultChecked ? "scale(.96)" : "scale(.8)",
  cursor: "pointer",
  userSelect: "none",
  transition: theme.transitions.create(["transform"]),
  ":hover": {
    transform: "scale(1)",
  },
}));

export const StyledInput = styled(InputBase)(
  ({ theme, error, size = "medium" }) => ({
    backgroundColor: theme.palette.primary.main + "10",
    width: "100%",
    fontSize: size === "medium" ? 16 : 14,
    borderRadius: 8,
    padding:
      size === "medium" ? `${theme.spacing(1)} ${theme.spacing(2)}` : `4px 8px`,
    border: "1px solid",
    borderColor: error
      ? theme.palette.error.main + 60
      : theme.palette.primary.main + "10",
    ":focus-within": {
      borderColor: theme.palette.primary.main + "40",
      backgroundColor: theme.palette.primary.main + "20",
    },
  })
);

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  borderRadius: 8,
}));

StyledButton.defaultProps = {
  disableElevation: true,
  variant: "contained",
};
