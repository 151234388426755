import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./Feedback.css";
import { motion } from "framer-motion";
import { styled } from "@mui/material";

const StyledCard = styled(motion(Card))(({ theme }) => ({
  width: "100%",
}));

function Feedback(props) {
  return (
    <StyledCard
      initial={{ scale: 0.86, opacity: 0.8 }}
      animate={{ scale: 0.96, opacity: 1 }}
      className={props.i % 2 === 0 ? "container-white" : "container-blue"}
    >
      <CardContent>
        <Typography
          className={
            props.i % 2 === 0
              ? "feedback-name-typo-white"
              : "feedback-name-typo-blue"
          }
        >
          {'" ' + props.feedback.name + ' "'}
        </Typography>
        <Typography
          className={
            props.i % 2 === 0
              ? "feedback-content-typo-white"
              : "feedback-content-typo-blue"
          }
        >
          {props.feedback.feedback}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}
export default Feedback;
