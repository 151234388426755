import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Stack,
  Box,
  styled,
  Dialog,
  Zoom,
  Chip,
} from "@mui/material";
import "./ProductDisplay.css";
import { storageUrl } from "../../utils/API";
import SubscriptionForm from "../ProductCard/Form";

const MainImage = styled("img")(({ theme }) => ({
  maxWidth: 350,
  width: "100%",
  aspectRatio: 1 / 1,
  borderRadius: "4px",
  objectFit: "cover",
}));

const Image = styled("img")(({ theme, isActive }) => ({
  boxSizing: "border-box",
  border: isActive ? "4px solid" : "none",
  borderColor: "#fff",
  cursor: "pointer",
  width: 50,
  transition: theme.transitions.create(["border"], {
    duration: "0.1s",
  }),
  height: 50,
  borderRadius: 4,
}));

const ProductDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!location?.state?.data) return navigate("/shop");
    setProduct(location?.state?.data);
  }, [location, navigate]);

  const displayImages = useMemo(
    () =>
      !product?.images?.length
        ? null
        : product.images.map((img, i) => (
            <Image
              key={1}
              isActive={active === i}
              onClick={() => setActive(i)}
              src={storageUrl.concat(img)}
            />
          )),
    [product, active]
  );

  const isOutOfStack = !Boolean(product?.outOfStock) ? null : (
    <Chip
      component="div"
      onClick={handleOpen}
      label="Out of stock subscription"
      sx={{
        cursor: "pointer",
        borderRadius: "4px",
        width: { xs: "100%", md: "inherit" },
      }}
      color="warning"
    />
  );

  return (
    <Container className="productdisplay">
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Zoom}
      >
        <SubscriptionForm handleClose={handleClose} productId={product?.id} />
      </Dialog>
      <Grid container spacing={4} className="productdisplay-container-grid">
        <Grid item xs={12} md={5} display="flex">
          <Box display="grid" gridTemplateColumns="50px 1fr" gap="8px">
            <Stack gap={1}>{displayImages}</Stack>
            <MainImage
              src={storageUrl.concat(
                product?.images?.length ? product?.images[active] : ""
              )}
              alt="product"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            alignItems="center"
            display="flex"
            mb={2}
            flexWrap="wrap"
            gap="8px"
            justifyContent="space-between"
          >
            <Typography className="productdisplay-product-name">
              {product?.name}
            </Typography>
            {isOutOfStack}
          </Box>
          <Stack container item xs={12} md={6} direction="row">
            <Typography className="productdisplay-product-price">
              NOK {product?.price}
            </Typography>
            {product?.oldPrice && (
              <Typography className="productdisplay-product-old-price">
                {" "}
                {`NOK ${product?.oldPrice}`}
              </Typography>
            )}
          </Stack>
          <Typography className="productdisplay-product-description">
            {product?.description}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDisplay;
