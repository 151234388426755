import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import Shop from "./Pages/Shop";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer/Footer";
import ProductDisplay from "./Components/ProductDisplay/ProductDisplay";
import Feedback from "./Components/Feedback/Feedback";
import ScrollToTop from "./Components/ScrollToTop";

export default function MainRouter() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<ProductDisplay />}>
          <Route path=":productId" element={<ProductDisplay />} />
        </Route>
      </Routes>
      <Feedback />
      <Footer />
    </Router>
  );
}
