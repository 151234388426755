import React, { useState } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../Assets/nav-logo.png";

const menuItems = Object.freeze([
  {
    path: "/",
    label: "Home",
  },
  {
    path: "/shop",
    label: "Shop",
  },
  {
    path: "/aboutus",
    label: "About us",
  },
  {
    path: "/contact",
    label: "Contact",
  },
]);

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggle = () => setMenuOpen((prev) => !prev);
  const handleClose = (e) => {
    e.preventDefault();
    if (menuOpen) setMenuOpen(!menuOpen);
  };

  const renderMenuList = menuItems.map((menu, i) => (
    <li key={i} className="menu-item" onClick={handleClose}>
      <NavLink to={menu.path}>{menu.label}</NavLink>
    </li>
  ));

  return (
    <nav>
      <Link to="/" style={{ textDecoration: "none" }} className="nav-logo">
        <img src={logo} alt="logo" />
      </Link>
      <div className="nav-login-cart"></div>
      <div className="menu" onClick={handleToggle}>
        <span />
        <span />
        <span />
      </div>
      <ul className={!menuOpen ? "close" : "open"}>{renderMenuList}</ul>
    </nav>
  );
};

export default Navbar;
