import { Box, DialogContent, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { axiosInstance } from "../../utils/API";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { EmojiBox, StyledButton, StyledInput } from "./Styles";
import { withSnackbar } from "../../HOC/withSnackbar";

const emojis = Object.freeze(["😞", "😕", "😑", "😊", "🥰"]);

const validations = yup.object().shape({
  rating: yup.number().required().label("Rating"),
  name: yup.string().min(3).max(50).required().label("Name"),
  feedback: yup.string().min(3).max(250).required().label("Feedback"),
});

function FeedbackForm({ handleClose, openSnackbar }) {
  const [isSubmitted, setSubmitted] = useState(false);

  const [formValues] = useState({
    rating: null,
    name: "",
    feedback: "",
  });
  const handleSubmit = async (values, form) => {
    try {
      await axiosInstance.post("/feedback", values);
      setSubmitted(true);
      form.resetForm();
      openSnackbar("Feedback submitted!", "success");
    } catch (err) {
      openSnackbar("Failed submit Feedback! please try again Later.");
      console.error(err);
    } finally {
      return;
    }
  };

  const displayEmojis = useMemo(
    () => (
      <Field>
        {({ form: { values, setFieldValue } }) =>
          emojis.map((e, i) => (
            <EmojiBox
              key={i}
              defaultChecked={values?.rating === i}
              onClick={() => setFieldValue("rating", i)}
            >
              {e}
            </EmojiBox>
          ))
        }
      </Field>
    ),
    []
  );

  const isHasError = (touched, errors, name) =>
    !!touched[name] && !!errors[name];

  return (
    <DialogContent>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {({ errors, touched, ...rest }) => (
          <Form>
            {isSubmitted ? (
              <Stack alignItems="center" gap={3}>
                <Typography
                  textAlign="center"
                  variant="h4"
                  fontWeight="bold"
                  color="primary"
                >
                  Thank you for your feedback.
                  <Typography
                    textAlign="center"
                    color="text.secondary"
                    variant="h6"
                  >
                    Your feedback has submitted successfully.
                  </Typography>
                </Typography>
                <StyledButton size="large" onClick={handleClose}>
                  Okay
                </StyledButton>
              </Stack>
            ) : (
              <Stack gap={3}>
                <div>
                  <Typography textAlign="center" variant="h6" fontWeight="bold">
                    Give Feedback
                  </Typography>
                  <Typography
                    textAlign="center"
                    variant="subtitle2"
                    color={"text.secondary"}
                  >
                    How is your experience about Aqua experts?
                  </Typography>
                </div>
                <Box display="flex" gap={1} margin={"auto"}>
                  {displayEmojis}
                </Box>
                {isHasError(touched, errors, "rating") && (
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    textAlign="center"
                    color={"error"}
                  >
                    {errors["rating"]}
                  </Typography>
                )}
                <div>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    color={
                      isHasError(touched, errors, "name")
                        ? "error"
                        : "text.secondary"
                    }
                  >
                    {isHasError(touched, errors, "name")
                      ? errors["name"]
                      : "Your name *"}
                  </Typography>
                  <Field
                    error={isHasError(touched, errors, "name")}
                    as={StyledInput}
                    name="name"
                    placeholder="Please enter your name"
                  />
                </div>

                <div>
                  <Typography
                    gutterBottom
                    color={
                      !isHasError(touched, errors, "feedback")
                        ? "text.secondary"
                        : "error"
                    }
                    variant="subtitle2"
                  >
                    {isHasError(touched, errors, "feedback")
                      ? errors["feedback"]
                      : "How is your experience about Aqua experts? *"}
                  </Typography>
                  <Field
                    error={isHasError(touched, errors, "feedback")}
                    as={StyledInput}
                    name="feedback"
                    multiline
                    rows={4}
                    placeholder="Your feedback"
                  />
                </div>
                <div>
                  <Typography variant="subtitle2" color="error"></Typography>
                </div>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <StyledButton color="inherit" onClick={handleClose}>
                    Close
                  </StyledButton>
                  <StyledButton type="submit">Submit</StyledButton>
                </Box>
              </Stack>
            )}
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default withSnackbar(FeedbackForm);
