import React, { useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ProductsGridView from "../Components/ProductsGridView/ProductsGridView";

import "./CSS/Shop.css";
import {
  useGetAllCategoriesQuery,
  useGetAllProductsByCategoryQuery,
} from "../Features/Api/ProductApiSlice";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Shop = () => {
  const { data } = useGetAllCategoriesQuery();

  const categories = useMemo(() => {
    if (!data?.data?.length) return [];
    return data?.data;
  }, [data]);

  const [value, setValue] = React.useState("");

  const activeCategory = useMemo(
    () => (!isNaN(value) ? categories[value] : null),
    [value, categories]
  );

  const { data: productsData } = useGetAllProductsByCategoryQuery(
    activeCategory?.id || ""
  );

  const products = useMemo(
    () => (!productsData?.data?.length ? [] : productsData?.data),
    [productsData]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box overflow="scroll" className="custom-panel-tab-menu-container">
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="custom-panel-tab-menu"
          indicatorColor="#3FADEB"
        >
          {categories.map((c, i) => (
            <Tab
              key={i}
              id={i}
              className="custom-panel-tab-menu-typo"
              label={c.name}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Box>

      <ProductsGridView
        productList={products}
        title={activeCategory?.name ?? "All categories"}
      />
    </>
  );
};
export default Shop;
