import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";

import footer_logo from "../Assets/logo.png";
import email_icon from "../Assets/email.svg";
import whatsapp_icon from "../Assets/whatsapp.svg";

import "./Footer.css";
import { StyledButton, StyledInput } from "../Feedback/Styles";
import { withSnackbar } from "../../HOC/withSnackbar";
import { axiosInstance } from "../../utils/API";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

const Footer = (props) => {
  const validation = yup.object().shape({
    email: yup.string().email().required().label("Email"),
  });
  const handleSubscribe = async (values, form) => {
    try {
      await axiosInstance.post("/subscribers", values);
      props.openSnackbar("Successfully Subscribed!", "success");
      form.resetForm();
    } catch (error) {
      props.openSnackbar(
        error.response?.data.message ?? "Failed to subscribe!",
        "error"
      );
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Grid container xs={12} className="footer-link-container">
          <Grid
            item
            container
            sm={12}
            md={12}
            lg={5}
            justifyContent="flex-start"
          >
            <Grid
              item
              container
              xs={12}
              md={4}
              className="footer-link-sub-container"
              direction="column"
            >
              <Typography className="footer-title-typo">Quick Links</Typography>
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="quick-links-typo"
              >
                {" "}
                Home{" "}
              </Link>
              <Link
                to="/shop"
                style={{ textDecoration: "none" }}
                className="quick-links-typo"
              >
                {" "}
                Shop{" "}
              </Link>
              <Link
                to="/aboutus"
                style={{ textDecoration: "none" }}
                className="quick-links-typo"
              >
                {" "}
                About Us{" "}
              </Link>
              <Link
                to="/contact"
                style={{ textDecoration: "none" }}
                className="quick-links-typo"
              >
                {" "}
                Contact{" "}
              </Link>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={8}
              className="footer-link-sub-container"
              direction="column"
            >
              <Typography className="footer-title-typo">Products</Typography>
              <Grid item container direction="row">
                <Grid item container direction="column" xs={6} lg={6}>
                  <Link
                    to="/shop"
                    state={{ data: 0 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Discus{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 1 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Arowana{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 2 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Angel{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 3 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Gourami{" "}
                  </Link>
                </Grid>
                <Grid item container direction="column" xs={6} lg={6}>
                  <Link
                    to="/shop"
                    state={{ data: 4 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Platty{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 5 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Tetra{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 6 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Flower Horn{" "}
                  </Link>
                  <Link
                    to="/shop"
                    state={{ data: 7 }}
                    style={{ textDecoration: "none" }}
                    className="quick-links-typo"
                  >
                    {" "}
                    Ramirez{" "}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm="12"
            md="12"
            lg="7"
            className="footer-links-container"
            justifyContent="flex-end"
          >
            <Grid
              item
              container
              xs="12"
              md="6"
              className="footer-link-sub-container"
              alignContent="flex-start"
            >
              <Typography className="footer-title-typo">
                Contact Info
              </Typography>
              <Typography className="footer-common-typo">
                <img
                  src={email_icon}
                  className="contact-info-email-icon"
                  alt="logo"
                />
                <a
                  href="mailto:norwayaquaexperts@gmail.com"
                  target="_blank"
                  className="contact-info-email-link"
                  rel="noreferrer"
                >
                  norwayaquaexperts@gmail.com
                </a>
              </Typography>
              <Typography className="footer-common-typo">
                <img
                  src={whatsapp_icon}
                  className="contact-info-whatsapp-icon"
                  alt="logo"
                />
                <a
                  href="https://wa.me/4796960777"
                  className="contact-info-whatsapp-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  +47 9696 0777
                </a>{" "}
                ( Whats App Orders )
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs="12"
              md="6"
              className="footer-link-sub-container"
              alignContent="flex-start"
            >
              <Typography className="footer-title-typo">
                Opening Hours
              </Typography>
              <Typography className="footer-common-typo">
                Monday – Sunday: 09:00AM – 06:00PM
              </Typography>
              <Typography className="footer-common-typo">
                Public Holidays: 09:00AM – 06:00PM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik
                validationSchema={validation}
                initialValues={{ email: "" }}
                onSubmit={handleSubscribe}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <Box
                      display="grid"
                      gap="4px"
                      bgcolor={"#07223E"}
                      p={2}
                      mb={2}
                      borderRadius={2}
                    >
                      <Typography
                        variant="h6"
                        color={"primary"}
                        fontWeight="bold"
                      >
                        Subscribe to Newsletter
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={"#fff"}
                        gutterBottom
                        mb={2}
                      >
                        Be the first to get exclusive offer and the latest news.
                      </Typography>
                      <Box
                        display="flex"
                        gap={2}
                        justifyContent="space-between"
                      >
                        <Field
                          fullWidth
                          sx={{ bgcolor: "#fafafa" }}
                          as={StyledInput}
                          name="email"
                          placeholder="Subscriptions - Enter your Email"
                        />
                        <StyledButton
                          size="large"
                          disabled={isSubmitting}
                          type="submit"
                          color="inherit"
                        >
                          Subscribe
                        </StyledButton>
                      </Box>
                      <Typography variant="subtitle2" color="error">
                        {!!touched["email"] && errors["email"]}
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className="footer-logo-container"
          alignItems="center"
        >
          <Grid item container lg={5} className="footer-logo">
            <img src={footer_logo} alt="logo" height="100px" />
          </Grid>
          <Grid
            item
            container
            lg={7}
            className="footer-logo"
            justifyContent="end"
          >
            <Typography className="all-right-reserved-typo">
              Designed and Developed by{" "}
              <a
                href="https://neirahtech.com/"
                target="_blank"
                className="footer_web_link"
              >
                Neirah Tech
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default withSnackbar(Footer);
