import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./BaseQuery";

export const productApiSlice = createApi({
  reducerPath: "productApi",
  baseQuery,
  endpoints: (builder) => ({
    // products
    getAllProducts: builder.query({
      query: () => `/products`,
    }),
    getAllFavorite: builder.query({
      query: () => `/products/favorite`,
    }),
    getLatest: builder.query({
      query: () => `/products/latest`,
    }),
    getAllProductsByCategory: builder.query({
      query: (cid = "") => `/products/getByCategory?id=${cid}`,
    }),
    // categories
    getAllCategories: builder.query({
      query: () => `/categories`,
    }),
    // feedback
    getFeaturedFeedbacks: builder.query({
      query: (page = 1) => `/feedback/featured?page=${page}`,
    }),
    // slider
    getSliders: builder.query({
      query: () => `/slider`,
    }),
    subscribe: builder.mutation({
      query: (body) => ({
        url: `/subscribers`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useSubscribeMutation,
  useGetSlidersQuery,
  useGetAllCategoriesQuery,
  useGetAllProductsByCategoryQuery,
  useGetAllProductsQuery,
  useGetAllFavoriteQuery,
  useGetLatestQuery,
  useGetFeaturedFeedbacksQuery,
} = productApiSlice;
